






/**
 * Created by wangtong on 2022/2/10
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {PropType} from 'vue'
import {AuthQuery, createAuthQuery} from "@/models";

@Component({})
export default class Test extends Vue {

    auth: AuthQuery = createAuthQuery()

    mounted() {
        this.auth = {
            ...createAuthQuery(),
            ...this.$route.query
        }
    }
}
